import React from 'react'
import "./character.scss";
import { ReactComponent as CloseIcon } from "assets/images/icons/icon-cancel.svg"
import {ReactComponent as IconChecked} from "assets/images/icons/icon-check-el.svg"

const Characteristic = () => {
    return (
        <div className="character">
            <div className="character__header">
                <h3>Tesla Model S <span>Подробная характеристика</span></h3>
                <div className="closeModal">
                    <span>Закрыть</span>
                    <CloseIcon />
                </div>
            </div>
            <div className="character__info">
                <h3 className="title">Размер и масса</h3>
                <table class="info-table">
                    <tbody>
                        <tr>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                            <td className="property-name">Протектор шины (передние/sзадние), мм </td>
                            <td className="property">4572*1825*1496 </td>
                        </tr>
                        <tr>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                        </tr>
                        <tr>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                            <td className="property-name">Протектор шины (передние/sзадние), мм </td>
                            <td className="property">4572*1825*1496 </td>
                        </tr>
                        <tr>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                        </tr>
                    </tbody>
                </table>
                <h3 className="title">Эксплуатационные параметры</h3>
                <table class="info-table">
                    <tbody>
                        <tr>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                            <td className="property-name">Протектор шины (передние/sзадние), мм </td>
                            <td className="property">4572*1825*1496 </td>
                        </tr>
                        <tr>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                            <td className="property-name">Длина*ширина*высота, мм </td>
                            <td className="property">4572*1825*1496 </td>
                        </tr>

                    </tbody>
                </table>
                <h3 className="title">Эксплуатационные параметры</h3>
                <table class="info-table">
                    <tbody>
                        <tr>
                            <td className="property-name">Тип батареи </td>
                            <td className="property">Литий-ионный </td>
                            <td className="property-name">Ёмкость батареи, квт/ч </td>
                            <td className="property">≥53,6 </td>
                        </tr>   
                    </tbody>
                </table>
                <h3 className="title">Устройство безопасности</h3>
                <table class="info-table">
                    <tbody>
                        <tr>
                            <td className="property-name">Антиблокировочная система тормозов (ABS)</td>
                            <td className="property"><IconChecked /> </td>
                            <td className="property-name">Система распределения тормозных усилий (EBD)</td>
                            <td className="property"><IconChecked /></td>
                        </tr>
                        <tr>
                            <td className="property-name">Система стабилизации (ESP)</td>
                            <td className="property"><IconChecked /> </td>
                            <td className="property-name">Система помощи при подъеме (HAC)</td>
                            <td className="property"><IconChecked /></td>
                        </tr>
                        <tr>
                            <td className="property-name">Система помощи при подъеме (HAC)</td>
                            <td className="property"><IconChecked /> </td>
                            <td className="property-name">Контроль давления шин (TPMS)</td>
                            <td className="property"><IconChecked /></td>
                        </tr>   
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Characteristic