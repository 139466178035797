import axios from 'axios'

const request = axios.create({
    baseURL : "https://63965282a68e43e418017bd8.mockapi.io/fake/ttt",
    timeout: 8000,
})


request.interceptors.response.use((response) => response.data[0])

export default request