import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { ReactComponent as Homeicon2 } from "assets/images/icons/icon-home-fill.svg";

function handleClick(event) {
	event.preventDefault();
	console.info("You clicked a breadcrumb.");
}

const Breadcrumb = (props) => {
	return (
		<div className={`${props.whiteTheme ? "breadcrumbsWhite" : "breadcrumbs"}`} role="presentation" onClick={handleClick}>
			<Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
				<Link className={`${props.whiteTheme ? "whiteLink" : "defaultLink"}`} to="/">
					<Homeicon2 />
					Главная
				</Link>
				{props.data.map((value) => {
					const isLink = value.link;
					return isLink === "" ? (
						<Typography key={value.id} className={`${props.whiteTheme ? "whiteNoneLink" : "noneLink"}`}>{value.title}</Typography>
					) : (
						<Link key={value.id} className={`${props.whiteTheme ? "whiteLink" : "defaultLink"}`} to={`${value.link}`}>
							{value.title}
						</Link>
					);
				})}
			</Breadcrumbs>
		</div>
	);
};

export default Breadcrumb;
