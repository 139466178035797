import { makeAutoObservable } from 'mobx';
import { storage } from "../services";

export default class FavoriteStore {
  constructor(rootStore) {
    this.favoriteData = storage.get('fav') ? JSON.parse(storage.get('fav')) : [];

    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  addFavorite = (newItem) => {
    const newFavoriteData = [...this.favoriteData];
    newFavoriteData.push(newItem);

    storage.set('fav', JSON.stringify(newFavoriteData));
    this.setFavoriteData(newFavoriteData);
  };

  deleteFavorite = (id) => {
    const newFavoriteData = [...this.favoriteData].filter(item => item.id !== id);

    storage.set('fav', JSON.stringify(newFavoriteData));
    this.setFavoriteData(newFavoriteData);
  }

  setFavoriteData = (data) => {
    this.favoriteData = data;
  }
}
