import React, { useEffect, useRef, useState } from "react";

import "./carouselDefault.scss";


const Carousel = ({carImages, title, subtitle }) => {
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [selectedImage, setSelectedImage] = useState();
	const carouselItemsRef = useRef([]);

	useEffect(() => {
		if (carImages && carImages[0]) {
			carouselItemsRef.current = carouselItemsRef.current.slice(0, carImages.length);

			setSelectedImageIndex(0);
			setSelectedImage(carImages[0]);
		}
	}, []);

	const handleSelectedImageChange = (newIdx) => {
		if (carImages && carImages.length > 0) {
			setSelectedImage(carImages[newIdx]);
			setSelectedImageIndex(newIdx);
		}
	};
	return (
		<div className="carouselSlider" style={{ backgroundImage: `url(${selectedImage?.url})` }}>
			<div className="carouselSlider__wrapper-all">
				<div className="carouselSlider__bottomSection">
					<div className="carouselSlider__bottomSection-content">
						<h2 className="title">{title}</h2>
						<p className="subtitle">
							{subtitle}
						</p>
					</div>
				</div>
				<div className="carouselSlider__image-box">
					{carImages &&
						carImages?.map((image, idx) => {
							return (
								<div
									onClick={() => handleSelectedImageChange(idx)}
									style={{ backgroundImage: `url(${image?.url})` }}
									key={image.id}
									className={`carousel__img ${selectedImageIndex === idx && "img-selected"}`}
									ref={(el) => (carouselItemsRef.current[idx] = el)}>
									<div className="bgImg"></div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default Carousel;
