import { makeAutoObservable } from 'mobx';
import axios from "axios";

export default class ModelsStore {
  constructor(rootStore) {
    this.models = null;
    this.modelsOptions = null;
    this.modelsColors = null;
    this.brends = null;
    this.types = null;
    this.modelTypes = null;
    this.equipments = null;
    this.countries = null;

    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getModels = () => {
    this.rootStore.setLoading(true);
    return axios.post('/b/ap/stream/ph&models', { "is_web": "Y", "lang_code": "ru", "filial_id": "100" })
      .then(({ data }) => {
        this.setModels(data);
        this.setModelsColors(data);
        this.setBrands(data);
        this.setTypes(data);
        this.setModelTypes(data);
        this.setEquipment(data);
        this.setCountries(data);
        this.setModelsOptions(data);
        return data;
      });
  };

  setModels = (models = []) => {
    this.models = models;
  };

  setModelsOptions = (models = []) => {
    const transformData = models.map(model => {
      const colors = [];

      model.modifications.forEach(mod => {
        mod.colors.forEach(color => colors.push({
          color_id: color.color_id,
          hex_value: color.hex_value,
          name: color.name
        }));
      });

      return {
        id: model.model_id,
        brend: model.brend,
        country: model.country,
        colors,
        model: model.name,
        type: model.tips,
        equipment: model.modifications.map(mod => mod.name),
        price: model.modifications.map(mod => mod.name),
      }
    });

    this.modelsOptions = transformData;
  };

  setModelsColors = (models = [], hasSet = true) => {
    const colors = [];

    models.forEach(model => {
      model.modifications.forEach(mod => {
        mod.colors.forEach(color => {
          if (!colors.find(item => item.color_id === color.color_id)) {
            colors.push(color);
          }
        });
      })
    });

    if (hasSet) {
      this.modelsColors = colors;
    }

    return colors;
  };

  setEquipment = (models = [], hasSet = true) => {
    const equipments = [];

    models.forEach(model => {
      model.modifications.forEach(mod => {
        if (!equipments.find(item => item === mod.name)) {
          equipments.push(mod.name);
        }
      })
    });

    const resultEquipments = equipments.map(equipment => ({ item: equipment, id: equipment }));

    if (hasSet) {
      this.equipments = resultEquipments
    }

    return resultEquipments;
  };

  setCountries = (models = [], hasSet = true) => {
    const countries = [];

    models.forEach(model => {
      if (!countries.find(country => country === model.country) && model.country) {
        countries.push(model.country);
      }
    });

    const resultCountries = countries.map(country => ({ item: country, id: country }));
    if (hasSet) {
      this.countries = resultCountries;
    }
    return resultCountries;
  };

  setBrands = (models = [], hasSet = true) => {
    const brends = [];

    models.forEach(model => {
      if (!brends.find(brend => brend === model.brend)) {
        brends.push(model.brend);
      }
    });

    const resultBrends = brends.map(brend => ({ item: brend, id: brend }));
    if (hasSet) {
      this.brends = resultBrends
    }

    return resultBrends;
  };

  setModelTypes = (models = [], hasSet = true) => {
    const modelTypes = [];

    models.forEach(model => {
      if (!modelTypes.find(type => type === model.name)) {
        modelTypes.push(model.name);
      }
    });

    const types = modelTypes.map(model => ({ item: model, id: model }));

    if (hasSet) {
      this.modelTypes = types;
    }

    return types;
  };

  setTypes = (models = [],  hasSet = true) => {
    const types = [];

    models.forEach(model => {
      if (!types.find(types => types === model.tips) && model.tips) {
        types.push(model.tips);
      }
    });

    if (hasSet) {
      this.types = types;
    }

    return types;
  };
}
