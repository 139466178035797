import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { helpers } from "services";
import { storage } from "services";
import { observer } from 'mobx-react-lite';

import { Button } from "components";
import { ReactComponent as CompareIcon } from "assets/images/icons/icon-compare-states.svg";
import { ReactComponent as FavoriteIcon } from "assets/images/icons/icon-favorite.svg";
///Import Swiper styles///
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./catalogCardGor.scss";
import useStores from "../../hooks/use-stores";
import { useSnackbar } from "notistack";

const CatalogCardGorizontal = ({ carId, carName, carSubName, cost, costMonth, carImages, isAvailable, carData }) => {
	const {
		compareStore: { addCompare, compareData, deleteCompare, maxCountCompare },
		favoriteStore: { addFavorite, favoriteData, deleteFavorite },
	} = useStores();
	const { enqueueSnackbar } = useSnackbar();

	const changeCompare = () => {
		if (compareData.find(item => item.id === carId)) {
			deleteCompare(carId);
			enqueueSnackbar("Машина удалена из Сравнения");
		} else if (compareData.length !== maxCountCompare) {
			addCompare(carData);
			enqueueSnackbar("Машина добавлена в Сравнение");
		} else if (compareData.length === maxCountCompare) {
			enqueueSnackbar("В сравнении не может быть больше 3 машин", { variant: 'error' });
		}
	};

	const changeFavorite = () => {
		if (favoriteData.find(item => item.id === carId)) {
			deleteFavorite(carId);
			enqueueSnackbar("Машина удалена из Избранных")
		} else {
			addFavorite(carData);
			enqueueSnackbar("Машина добавлена в избранное")
		}
	};

	return (
		<div className="cardWrapperGorizontal">
			<div className="catalogCard">
				<div className="catalogCard__image">
					<div className="catalogCard__favoriteIcon">
						<div className="compare" onClick={changeCompare}>
							<CompareIcon />
							<h4 className="title">
								{compareData.find(item => item.id === carId) ? 'Убрать из сравнения' : 'Сравнить'}
							</h4>
						</div>
						<div className="favorite" onClick={changeFavorite}>
							<FavoriteIcon />
							<h4 className="title">
								{favoriteData.find(item => item.id === carId) ? 'Убрать из избранных' : 'В избранные'}
							</h4>
						</div>
					</div>
					<Swiper
						spaceBetween={30}
						centeredSlides={true}
						autoplay={false}
						pagination={{
							clickable: true
						}}
						navigation={false}
						modules={[Autoplay, Pagination, Navigation]}
						className="mySwiper">
						{carImages &&
							carImages?.map((imageItem) => {
								return (
									<SwiperSlide key={imageItem.id}>
										<img src={imageItem.image} alt="catalogCardImage" />
									</SwiperSlide>
								);
							})}
					</Swiper>
					<div className="catalogCard__titles">
						<h3 className="title">{carName}</h3>
						<p className="subtitle">{carSubName}</p>
						<p className={`${isAvailable ? isAvailable.count == 0 ? "notAvailable" : "available" : "notAvailable"}`}>{`${isAvailable ? isAvailable.count == 0 ? "Нет в наличии" : "В наличии" : "Нет в наличии"}`}</p>

					</div>
				</div>
				<div className="bgHoverDrop"></div>
				<div className="catalogCard__content">
					<div className="pricesWrapper">
						<div className="catalogCard__cost">
							<h3 className="title">Стоимость </h3>
							<p className="cost">
								{helpers.convertToReadable(cost)} <span>UZS</span>
							</p>
						</div>
						<div className="catalogCard__credit">
							<h3 className="cost-credit">Цена в рассрочку</h3>
							<p className="cost-month">
								от {helpers.convertToReadable(costMonth)} <span>UZS/мес</span>
							</p>
						</div>
					</div>
					<div className="catalogCard__hoverDrop">
						<div className="catalogCard__buttons">
							<Button.Link
								// title={`${!isAvailable || !isAvailable.count ? "Сделать предзаказ" : "Оформить заказ"}`}
								title="Оформить заказ"
								url={{
									pathname: "/configuration",
								}}
								state={{
									model: carId,
								}}
								type="default"
								hasIconArrow={false}
								hasIcon={false}
								width="100"
								size="large"
								borderType="full"
								rounded={true}
							/>
							<Button.Link
								title="Подробнее"
								url={`/about-car/${carId}`}
								type="light"
								hasIconArrow={false}
								hasIcon={false}
								width="100"
								size="large"
								borderType="full"
								rounded={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(CatalogCardGorizontal);
