import { Portal } from "components";
import { Layout } from "./Layout";

import { useMount } from "hooks/useMount";

export const PurchaseAnimatedModal = ({ opened, title, onClose, children }) => {
  const { mounted } = useMount({ opened });

  if (!mounted) {
    return null;
  }

  return (
    <Portal>
      <Layout onClose={onClose} title={title} opened={opened}>
        {children}
      </Layout>
    </Portal>
  );
};
