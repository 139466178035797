import React, { useState } from "react";
import "./defaultSelect.scss";

const DefaultSelect = ({ title, IconSelect, beforeIcon, selectOptions, defaultItem, blackTheme, IconArrow, onChange, empty }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);

	const toggling = () => {
		setIsVisible(!isVisible);
	};

	const onOptionClicked = (value) => () => {
		setSelectedOption(value);
		setIsVisible(false);
		if(onChange) {
			onChange(value)
		}
	};

	return (
		<div className="choose-select">
			<div className="select">
				<div className={`${blackTheme ? "black-theme" : "select__header"}`} onClick={toggling}>
					<div className="select-items">
						{beforeIcon && <img src={IconSelect} alt="sort icon" />}
						<div className="text">
							<h3 className="title">{title}</h3>
							<span className="selected">{empty ? '' : (selectedOption || defaultItem)}</span>
						</div>
					</div>
					<img className={isVisible ? "upIcon" : "downIcon"} src={IconArrow} alt="icon arrow" />
				</div>
				{
					<ul className={`select__list ${isVisible ? "maxHeight" : "minHeight"}`}>
						{selectOptions.map((option) => {
							return (
								<li onClick={onOptionClicked(option)} key={Math.random()} className="item">
									{option}
								</li>
							);
						})}
					</ul>
				}
			</div>
		</div>
	);
};

export default DefaultSelect;
