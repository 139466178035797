import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdClose } from "react-icons/md";
import IconDown from "assets/images/icons/icon-arrow-down-blue.svg";
import "./MSelect.scss";

const listPerRow = 4;

const MSelect = ({ title, dropList, list, amount, showAmount = false, setList }) => {
	const [drop, setDrop] = useState(false);
	const [next, setNext] = useState(listPerRow);

	const handleMoreList = () => {
		setNext(next + listPerRow);
	};

	console.log("list", dropList);
	console.log("next", next);
	const handleChange = () => {
		setDrop(!drop);
	};

	const handAddList = (e, text) => {
		if (e.target.checked) {
			setList([...list, text]);
		} else {
			setList(list.filter((item) => item.id !== text.id));
		}
	};

	return (
		<div>
			<div className="cardM">
				<div className="cardM__box" onClick={handleChange}>
					<p className="cardM__text">{title}</p>
					{drop ? <IoIosArrowUp className="cardM__icon" /> : <IoIosArrowDown className="cardM__icon" />}
				</div>
				<div className={`${drop ? "cardM__active" : "cardM__menu"}`}>
					{dropList &&
						dropList.slice(0, next).map((text) => (
							<div className="item">
								<label htmlFor={text.id} key={text.id} className="cardM__active-item">
									<input
										type="checkbox"
										checked={list && !!list?.find((item) => item.id === text.id)}
										name={text.id}
										id={text.id}
										onChange={(e) => handAddList(e, text)}
									/>
									{text.item}
								</label>
								{showAmount && <span>({amount})</span>}{" "}
							</div>
						))}
					<h5 className={next > dropList.length ? "hide" : "show"} onClick={handleMoreList}>
						Показать еще <img src={IconDown} alt="icon down" />
					</h5>
				</div>
			</div>
		</div>
	);
};

export default MSelect;
