import { makeObservable, action, observable } from 'mobx';
import ModelsStore from './ModelsStore';
import CompareStore from './CompareStore';
import FavoriteStore from "./FavoriteStore";
import ContentGuideStore from "./ContentGuideStore";

export class RootStore {
  constructor() {
    this.isLoading = false;
    this.title = '';

    this.modelsStore = new ModelsStore(this);
    this.compareStore = new CompareStore(this);
    this.favoriteStore = new FavoriteStore(this);
    this.contentGuideStore = new ContentGuideStore(this);

    makeObservable(this, { isLoading: observable, title: observable, setLoading: action, setTitle: action });
  }

  setTitle = (title) => {
    this.title = title;
  };

  setLoading = (isLoading) => {
    this.isLoading = isLoading;
  };
}
