import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { get } from "lodash";

import CloseIcon from 'assets/images/icons/close-outline.svg'
import burger from "assets/images/icons/icon-gamburger.svg";
import SearchIcon from 'assets/images/icons/icon-search.svg'
import IconCatalog from 'assets/images/icons/icon-catalog.svg'
import ArrowIcon from 'assets/images/icons/icon-arrow-down-fill.svg'
import HeaderLogo from 'assets/images/main-page/LogoEvolution.svg'
import { ReactComponent as IconBasket } from 'assets/images/icons/icon-basket1.svg'
import { ReactComponent as IconFavorite } from 'assets/images/icons/icon-favorite.svg'
import { ReactComponent as IconCompare } from 'assets/images/icons/icon-compare-states.svg'
import SelectLang from './components/SelectLang'

const menus = [
	{ title: "Главная", url: "/", status: 1 },
	{ title: "Каталог", url: "catalog", status: 1 },
	{
	  title: "Владельцам",
	  url: "#",
	  status: 1,
	  menu_items: [{
		title: "Сервис",
		url: "/services-list/service",
		status: 1,
		menu_items: []
	  },
		// {
		//   title: "Карта станций",
		//   url: "station",
		//   status: 1,
		//   menu_items: []
	  // },
		{
		  title: "Интерактивная карта",
		  url: "/services-list/interactive-map",
		  status: 1,
		  menu_items: []
		}]
	},
	{
	  title: "Покупателям",
	  url: "#",
	  status: 1,
	  menu_items: [{
		title: "Конфигуратор",
		url: "configuration",
		status: 1,
		menu_items: []
	  }, {
		title: "Кредит",
		url: "/services-list/credit-lising",
		status: 1,
		menu_items: []
	  },
		{
		  title: "Trade In",
		  url: "/trade-in",
		  status: 1,
		  menu_items: []
		},{
		  title: "Оформить страховку",
		  url: "/getinsurance",
		  status: 1,
		  menu_items: []
		}]
	},
	{ title: "Дилерам", url: "/dealers", status: 1, menu_items: [] },
	{ title: "Новости и акции", url: "news", status: 1, menu_items: [] },
	{ title: "О компании", url: "about", status: 1, menu_items: [] },
	{ title: "Контакты", url: "contacts", status: 1, menu_items: [] }
  ];
  
const MobilHeader = ({ currentRoute }) => {
	const [isOpen, setIsOpen] = useState(false);
	let location = useLocation();
	const [isActive, setIsActive] = useState(false);

	const CloseBurgerMenu = () => {
		setIsOpen(false);
	};
	const OpenBurgerMenu = () => {
		setIsOpen(true);
	};
	const usePrevLocation = (location) => {
		const prevLocRef = useRef(location);

		useEffect(() => {
			prevLocRef.current = location;
		}, [location]);

		return prevLocRef.current;
	};
	const prevLocation = usePrevLocation(location);

	useEffect(() => {
		if (location !== prevLocation) {
			setIsOpen(false);
		}
	}, [location, prevLocation]);

	return (
		<>
			<div className="mobilWrapper">
				<div className="mob-header">
					<div className="logo-box">
						<Link
							to='/'>
							<img src={HeaderLogo} alt="HeaderLogo" />
						</Link>
					</div>
					<div className="burger-mob" >
						{
							!isOpen ?
								(<img onClick={OpenBurgerMenu} src={burger} className="burgerIcon" alt="mobile burger" />) : (
									<img onClick={CloseBurgerMenu} src={CloseIcon} className="closeIcon" alt="close icon" />
								)
						}
					</div>
				</div>
				<nav className={isOpen ? "mob-menu" : "closed "}>
					<div className="mob-menu__search">
						<img className="searchIcon" src={SearchIcon} alt="searchIcon" />
					</div>
					<ul className="mob-menu__nav">
						{menus.map((menu, i) => {
							return (
								<li key={i}
									className={`nav__item ${`${currentRoute}` === menu.url || (menu.url === "/" && currentRoute.length === 0) ? "active" : ""
										} ${(menu.menu_items || []).length > 0 && "menu-dropdown"}`}>{(menu.menu_items || []).length == 0 ? (
											<Link className="bordered" to={`${menu.url === "/" ? "" : menu.url}`}>{menu.title}</Link>
										) : (
											<>
												<Link
													to={menu.url}
													className={
														`bordered ${menu.menu_items.map(m => m.url === `${currentRoute}`)[0] ||
															menu.menu_items.map(m => m.url === `${currentRoute}`)[1]
															? "activeDropdown"
															: ""}`
													}>
													{menu.url === "catalog" ? (
														<img className="IconCatalog" src={IconCatalog} alt="IconCatalog" />
													) : null}
													{menu.title}
													<img className="IconArrow" src={ArrowIcon} alt="arrow-down" />
												</Link>
												<div className="transparent-box">
													<div className="rating-dropdown">
														{menu.menu_items.map((submenu, k) => {
															if (submenu.status === 1) {
																return (
																	<div key={k}>
																		<Link
																			to={submenu.url}
																			className={`dropdown__item ${(`${currentRoute}` === submenu.url ||
																				`/${currentRoute}` === submenu.url) &&
																				"activeSub"}`}
																			key={k}>
																			<span>{submenu.title}</span>
																		</Link>
																		<div className="divider"></div>
																	</div>
																);
															} else {
																return "";
															}
														})}
													</div>
												</div>
											</>
										)
									}</li>
							);

						})}
					</ul>
					<h3 className="mob-menu__support">Служба клиентской поддержки <span className="contact-number">+998 (78) 141-88-88</span></h3>

				</nav>
			</div>
		</>
	)
}

export default MobilHeader
