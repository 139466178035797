import { useState, useEffect } from "react";

export const UseTimer = (initialMinute, initialSeconds) => {
	const [minutes, setMinutes] = useState(initialMinute);
	const [seconds, setSeconds] = useState(initialSeconds);

	useEffect(() => {
		const myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
					setMinutes(initialMinute);
					setSeconds(initialSeconds);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, [initialMinute, initialSeconds, minutes, seconds]);

	return {
		minutes,
		seconds
	};
};


