import React, { lazy, Suspense } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import { Spinner, Layout } from "components";
import App from "./App";
import ProfileMain from "./pages/UserProfile/pages/Main";
import ProfileAplication from "./pages/UserProfile/pages/MyAplication";
import ProfileCar from "./pages/UserProfile/pages/MyCar";
import CarInfo from "./pages/UserProfile/pages/MyCar/pages/CarInfo";
import ProfileService from "./pages/UserProfile/pages/Service";
import ProfilePayment from "./pages/UserProfile/pages/Payment";
import ScrollTop from "./hoc/ScrollTop";
import { StoreContextProvider } from './stores/StoreContext';
import { RootStore } from './stores/RootStore';
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import defaultTheme from './theme';

const Home = lazy(() => import("./pages/Home"));
const Catalog = lazy(() => import("./pages/Catalog"));
const Accessories = lazy(() => import("./pages/Accessories"));
const AccessoryPage = lazy(() => import("./pages/Accessories/pages/Accessory"));
const AboutCar = lazy(() => import("./pages/AboutCar"));
const Basket = lazy(() => import("./pages/Basket"));
const Favorite = lazy(() => import("./pages/Favorite"));
const Compare = lazy(() => import("./pages/Compare"));
const Contacts = lazy(() => import("./pages/Contacts"));
const News = lazy(() => import("./pages/News"));
const NewsDetail = lazy(() => import("./pages/News/NewsDetail"));
const Purchase = lazy(() => import("./pages/Purchase"));
const Characteristic = lazy(() => import("./components/Characteristic"));
const GetInsuranceOnline = lazy(() => import("./pages/ServicesList/pages/GetInsuranceOnline"));
const GetServiceOnline = lazy(() => import("./pages/ServicesList/pages/GetServiceOnline"));
const InsurancePage = lazy(() => import("./pages/ServicesList/pages/InsurancePage"));
const ServicesBestCar = lazy(() => import("./pages/ServicesList/pages/ServicesBestCar"));
const CreditLising = lazy(() => import("./pages/ServicesList/pages/CreditLising"));
const InteractiveMap = lazy(() => import("./pages/ServicesList/pages/InteractiveMap"));
const DealersForm = lazy(() => import("./pages/DealersForm"));
const ServicesList = lazy(() => import("./pages/ServicesList"));
const AboutCompany = lazy(() => import("./pages/AboutCompany"));
const Configuration = lazy(() => import("./pages/Configuration"));
const TestDrive = lazy(() => import("./pages/TestDrive"));
const UserProfile = lazy(() => import("./pages/UserProfile"));
const NotFound = lazy(() => import("./pages/404"));
const TradeIn = lazy(() => import("./pages/TradeIn"));
const PayList = lazy(() => import("./pages/Configuration/pages/PurchaseMehtods/PaymentList"));
const OnlinePayments = lazy(() => import("./pages/Configuration/pages/PurchaseMehtods/OnlinePayments"));
const Payme = lazy(() => import("./pages/Configuration/pages/PurchaseMehtods/PaymentOfPayme"));

const routes = [
  { path: "", element: Home },
  { path: "/catalog", element: Catalog },
  { path: "/accessories", element: Accessories },
  { path: "/accessories/accessory-page", element: AccessoryPage },
  { path: "/about-car/:id", element: AboutCar },
  { path: "/basket", element: Basket },
  { path: "/favorite", element: Favorite },
  { path: "/compare", element: Compare },
  { path: "/contacts", element: Contacts },
  { path: "/news", element: News },
  { path: "/news-detail/:id", element: NewsDetail },
  { path: "/character", element: Characteristic },
  { path: "/purchase", element: Purchase },
  { path: "/configuration", element: Configuration },
  { path: "/test-drive", element: TestDrive },
  { path: "/getinsurance", element: GetInsuranceOnline },
  { path: "/getservice", element: GetServiceOnline },
  { path: "/services-list/insurance", element: InsurancePage },
  { path: "/services-list/service", element: ServicesBestCar },
  { path: "/services-list/credit-lising", element: CreditLising },
  { path: "/services-list/interactive-map", element: InteractiveMap },
  { path: "/services-list", element: ServicesList },
  { path: "/about", element: AboutCompany },
  { path: "/trade-in", element: TradeIn },
  { path: "/dealers", element: DealersForm },
  { path: "/paylist", element: PayList },
  { path: "/online-pay", element: OnlinePayments },
  { path: "/online-payme", element: Payme },
];

const store = new RootStore();

const RoutesContainer = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={defaultTheme}>
      <SnackbarProvider
        maxSnack={7}
        preventDuplicate
        dense={false}
        disableWindowBlurListener
      >
        <StoreContextProvider value={store}>
          <HashRouter>
            <App>
              <Layout>
                <Suspense fallback={<Spinner position="full"/>}>
                  <Routes>
                    {routes.map((route, key) => {
                      const RouteComponent = ScrollTop(route.element);
                      return <Route key={key} path={route.path} element={<RouteComponent/>}/>;
                    })}
                    <Route path="profile" element={<UserProfile/>}>
                      <Route index path="main" element={<ProfileMain/>}/>
                      <Route path="aplication" element={<ProfileAplication/>}/>
                      <Route path="mycar" element={<ProfileCar/>}/>
                      <Route path="carinfo" element={<CarInfo/>}/>
                      <Route path="service" element={<ProfileService/>}/>
                      <Route path="payments" element={<ProfilePayment/>}/>
                    </Route>
                    <Route path="*" element={<NotFound/>}/>
                  </Routes>
                </Suspense>
              </Layout>
            </App>
          </HashRouter>
        </StoreContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default RoutesContainer;
