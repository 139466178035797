import React from "react";
import { Button } from "components";
import { helpers } from "services";

import "./accessoryCard.scss";
//// Images ////
import IconMinus from "assets/images/icons/icon-minus.svg";
import IconPlus from "assets/images/icons/icon-pilus.svg";
import { ReactComponent as IconCompare } from "assets/images/icons/icon-compare-states.svg";
import { ReactComponent as IconBasket } from "assets/images/icons/icon-favorite.svg";

const AccessoryCard = (props) => {
	return (
		<div className="accessoryCard">
			<div className="accessoryCard__image">
				<img className="image-card" src={props.accessoryImage} alt="accessoryImage" />
				<div className="accessoryCard-top">
					<div className="compare">
						<IconCompare />
						<span>Сравнить</span>
					</div>
					<div className="basket">
						<IconBasket />
					</div>
				</div>
			</div>
			<div className="contentWrapper">
				<div className="accessoryCard__name">
					<h3 className="name">{props.name}</h3>
					<span className="brend">Бренд: {props.brend}</span>
				</div>
				<div className="accessoryCard__cost">
					<h3 className="cost">{helpers.convertToReadable(props.cost)} UZS</h3>
					<span className="title">Стоимость </span>
				</div>

				{props.addToCart ? (
					<div className="addToCart">
						<div className="basketButton">
							<Button.Link
								url=""
								type="default"
								hasIconArrow={false}
								hasIcon={true}
								iconBefore="basket"
								svgColor="white"
								size="large"
								borderType="half"
								rounded={true}
							/>
						</div>
						<div className="addItemButton">
							<img src={IconMinus} alt="icon minus" />
							<span>1</span>
							<img src={IconPlus} alt="icon minus" />
						</div>
					</div>
				) : (
					<Button.Default
						title="Добавит в корзину"
						buttonType="button"
						type="default"
						hasIconArrow={false}
						hasIcon={false}
						width="100"
						size="large"
						borderType="half"
						rounded={true}
					/>
				)}
			</div>
		</div>
	);
};

export default AccessoryCard;
