import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Select, Button } from "components";
import Fuse from 'fuse.js'
import axios from "axios";
import _ from "lodash";
import IconDown from "assets/images/icons/icon-arrow-down.svg";
import styles from "./search.module.scss";
import CarImage from "assets/images/cars/cherryCarr2.png";
import { useNavigate } from "react-router-dom";

const SortOptions = ["Автомобили", "Новости и акции"];

const Search = ({ setShowSearchModal }) => {
	const [data, setData] = useState([]);

	const navigate = useNavigate();
	const [news, setNews] = useState([]);
	const [cars, setCars] = useState([]);
	const [search, setSearch] = useState("");
	const handleSearchChange = (e) => {
		setSearch(e.target.value);
	};

	const handleSearchButton = () => {
		const fuse = new Fuse(data, {
			keys: [
				"name",
				"title",
				"text"
			]
		})
		let items = fuse.search(search)
		console.log(items)
		if (items.length) {
			items = items.map(e => {
				return e.item
			})
			setCars(items)
		} else {
			setCars(cars)
		}
	};
	const handleClick = (id) => {
		setShowSearchModal(false)
		navigate(`/about-car/${id}`);
	}
	const handleClick2 = (id) => {
		setShowSearchModal(false)
		navigate(`/news-detail/${id}`);
	}
	const kitcut = (text, limit) => {
		text = text.trim();
		if (text.length <= limit) return text;
		text = text.slice(0, limit); // тупо отрезать по лимиту
		let lastSpace = text.lastIndexOf(" ");
		if (lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
			text = text.substr(0, lastSpace);
		}
		return text + "...";
	}
	useLayoutEffect(() => {
		let d = []
		axios.post('/b/ap/stream/ph&models', { "is_web": "Y", "lang_code": "ru", "filial_id": "100" }).then(e => {
			for (let m of e.data) {
				m.type = "car"
				m.title = ""
				m.text = ""
				m.min = _.reduce(m.modifications, (memo, m2) => Math.min(memo || m2.price, m2.price), null);
				m.ph = `/b/core/m$load_image?sha=${m.photo_sha}`
				m.min2 = `от ${(m.min).toLocaleString('en-US', {
					style: 'currency',
					currency: 'UZS',
				}).substring(0, (m.min).toLocaleString('en-US', {
					style: 'currency',
					currency: 'UZS',
				}).length - 3).replace(",", " ").replace(",", " ").replace(",", " ")}`
			}
			d = d.concat(e.data)
			axios.post('/b/ap/stream/ph&get_news', { "is_web": "Y", "lang_code": "ru", "filial_id": "100" }).then(e => {
				for (let m of e.data) {
					m.name = ""
					m.type = "news"
					m.text = kitcut(m.text.replace(/(&nbsp;|<([^>]+)>)/ig, ""), 135)
				}
				d = d.concat(e.data)
				setData(d)
			})
		})
	}, []);

	const onKeyDown = (e) => {
		if (e.code === "Enter") {
			handleSearchButton();
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.items}>
				<div className={styles.sortItem}>
					<Select.DefaultSelect
						title="Все категории"
						beforeIcon={false}
						selectOptions={SortOptions}
						// defaultItem="По нарастанию"
						IconArrow={IconDown}
					/>
				</div>
				<input
					onChange={handleSearchChange}
					onKeyDown={onKeyDown}
					className={styles.inputSearch}
					type="text"
					placeholder="Поиск по сайту"
				/>
				<Button.Default onClick={handleSearchButton} title="Найти" type="default" size="large" rounded={true} borderType="full" hasIcon={false} hasIconArrow={false} />
			</div>
			{cars.length ? <div className={styles.searchContent} >
				<h3 className={styles.title}>Результат поиска</h3>
				<div className={styles.cardsBox}>
					{
						cars?.map((item) => {
							if (item.type == "car") {
								return (<div className={styles.carSearchcard}>
									<div className={styles.left}>
										<img className={styles.carImage} src={item.ph} alt="cardImage" />
										<div className={styles.titles}>
											<h3>{item.name}</h3>
											<h5>В наличии</h5>
										</div>
										<div className={styles.prices}>
											<h4>Стоимость </h4>
											<h3>{item.min2}</h3>
										</div>
									</div>
									<Button.Default
										title="Перейти"
										onClick={() => handleClick(item.model_id)}
										type="default"
										// url={{
										// 	pathname: "/configuration",
										// }}
										// state={{
										// 	model: item.model_id,
										// }}
										size="large"
										rounded={true}
										borderType="full"
										hasIcon={false}
										iconAfter="arrowbig-right"
										hasIconArrow={true}
										svgColor="white"
									/>
								</div>)
							} else if (item.type == "news") {
								return (<div className={styles.carSearchcard}>
									<div className={styles.left}>
										<div className={styles.texts}>
											<h4>{item.title}</h4>
											<h5>{item.text}</h5>
										</div>
									</div>
									<Button.Default
										title="Перейти"
										type="default"
										onClick={() => handleClick2(item.id)}
										size="large"
										rounded={true}
										borderType="full"
										hasIcon={false}
										iconAfter="arrowbig-right"
										hasIconArrow={true}
										svgColor="white"
									/>
								</div>)
							}
						})



					}

				</div>
			</div> : ""}
		</div>
	);
};

export default Search;
