import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components'

import "./style.scss"
import IconInstagram from "assets/images/social-icons/icon-instagram.svg"
import IconYoutube from "assets/images/social-icons/icon-youtube.svg"
import IconFacebook from "assets/images/social-icons/icon-facebook.svg"
import axios from "axios";
import { useSnackbar } from "notistack";

const PhoneReqExp = /^((\+998)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/
const Footer = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [nameError, setNameError] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (phone.length > 0 && !PhoneReqExp.test(phone)) {
      setPhoneError('Некорректный номер телефона');
    } else {
      setPhoneError('');
    }
  }, [phone, name]);

  const onSendForm = (event) => {
    event.preventDefault();

    if(!phoneError && !nameError) {
      axios.post('https://evolutionmotors.uz:3000/addFeedback', {
        title: name,
        text_1: phone
      }).then((e) => {
        if (e.data.status === 'OK') {
          enqueueSnackbar('Заявка отправлена!');
          setName('');
          setPhone('');
        }
      })
    }
  }

  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer__contact-us">
          <div className="contact-us">
            <span className="caption">ОБРАТНАЯ СВЯЗЬ</span>
            <h3 className="title">Свяжитесь с нами</h3>
            <p className="subtitle">Напишите нам и наши операторы свяжутся с вами</p>
          </div>

          <form className="form" onSubmit={onSendForm}>
            <div className="input-block">
            <input
              className="input"
              type="text"
              placeholder='ИМЯ'
              required
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <p className="text-error">{nameError}</p>
            </div>
            <div className="input-block">
            <input
              className="input"
              type="tel"
              required
              placeholder='контактный номер'
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
            <p className="text-error">{phoneError}</p>
            </div>
            <Button.Default
              title="Отправить"
              buttonType='submit'
              size="large"
              rounded={true}
              borderType="full"
              type='default'
              hasIcon={false}
              hasIconArrow={false}
            />
          </form>
        </div>
        <div className="footer__navigation" >
          <div className="footerNav">
            <h3 className="title">Основное</h3>
            <ul className="navlinks">
              <li className="link"><Link to="/">Главная</Link></li>
              <li className="link"><Link to="/catalog">Каталог</Link></li>
              <li className="link"><Link to="/configuration">Конфигуратор авто</Link></li>
              <li className="link"><Link to="/news">Новости</Link></li>
              <li className="link"><Link to="/contacts">Контакты</Link></li>
            </ul>
          </div>
          <div className="services">
            <h3 className="title">Услуги</h3>
            <ul className="navlinks">
              <li className="link"><Link to="/services-list/insurance">Страховка</Link></li>
              <li className="link"><Link to="/services-list/service">Сервис</Link></li>
              <li className="link"><Link to="/services-list/credit-lising">Кредит</Link></li>
            </ul>
          </div>
          <div className="contacts">
            <span className="title">Контакты</span>
            <div className="address">
            <span className='telNumber'>Тел: +998 78 141 88 88</span>
              <span>Email: info@evolutionmotors.uz</span>
            </div>
          </div>
          <div className="socials">
            <a href="https://instagram.com/evolution.motors.uzbekistan">
              <img src={IconInstagram} alt="instagram" />
            </a>
            <a href="https://t.me/evolutionmotorsuz">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/32px-Telegram_logo.svg.png" alt="twitter" />
            </a>
            <a href="https://www.youtube.com/channel/UC5PIXG4svRnsyPxKwCDpwNQ">
              <img src={IconYoutube} alt="youtube" />
            </a>
            <a href="https://facebook.com/evolution.motors.uzbekistan">
              <img src={IconFacebook} alt="facebook" />
            </a>
          </div>
        </div>
        <p className="footer__bottom">Evolution Motors, 2024
        </p>
      </div>
    </div>
  )
}


export default Footer
