import React from 'react'
import { Link } from "react-router-dom";
import "./creditLisingCard.scss"
import CarImage from "assets/images/cars/carTabImage.png"
import CarLogo from "assets/images/profile/carLogo.svg"
import ArrowRight from "assets/images/icons/Arrow-Right.svg"
import { useNavigate } from 'react-router-dom';

const CreditLisingCard = ({ order }) => {
  const navigate = useNavigate();
  const runPay = () => {
    // 👇️ navigate to /contacts
    navigate('/paylist', {

    });
  };
  return (
    <div className="creditLisingCard">
      <img className="creditLisingCard__carImage" src={order.photo_sha} alt="cardImage" />
      <div className="creditLisingCard__content">
        <h2 className="carName"><img src={CarLogo} alt="carLogo" />{order.model_name} {order.modification_name}</h2>
        <p className="dispatchTime">Номер контракта • {order.contract_code}</p>
        <div className="creditPrice">
          <h3>Цена</h3>
          <p className="price">{order.price} UZS</p>
        </div>
        <h2 className="deadlinePayment">Оплачено • {order.paid_amount} UZS</h2>
        <div className="creditStatus">
          <h3 className="status statusAproved">{order.state_html}</h3>
          <Link to="/paylist" state={{
            code: order.contract_code,
            amount: Number(order.remain_amount),
            price: order.price,
            name: order.model_name,
            photo: order.photo_sha,
            photo2: order.photo_sha2,
          }} className={`status statusAproved`} rel="noopener noreferrer">
            {true ? <>{`Оплатить`}</> : <>{`Оплатить`}</>}
          </Link>
          <img src={ArrowRight} alt="icon arrow" />
        </div>
      </div>
    </div>
  )
}

export default CreditLisingCard