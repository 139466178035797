import React, { useState } from "react";
import "./popupCreditAplication.scss";
import IconModel from "assets/images/icons/icon-logo.svg";
import IconPDF from "assets/images/icons/icon-PDF-red.svg";
import IconDownload from "assets/images/icons/icon-download-white.svg";
import CarImage from "assets/images/cars/carTabImage.png";
import CarImageInner from "assets/images/about-car/interior-4.jpg";
import IconColor from "assets/images/catalog/color-blue-royal.png";
import IconDisk from "assets/images/catalog/disk2.png";
import IconCheck from "assets/images/icons/icon-checkbox-elements.svg";
import IconSmartphone from "assets/images/icons/smartphone.svg";

const PopupCreditAplication = ({ selectedOrder }) => {
	const [changeTab, setChangeTab] = useState("tab1");

	const handleChangeTabOne = () => {
		setChangeTab("tab1");
	};
	const handleChangeTabTwo = () => {
		setChangeTab("tab2");
	};
	return (
		<div className="wrapperPopup">
			<div className="creditAplication">
				<div className="creditAplication__modelCar">
					<div className="creditAplication__modelCar-title">
						<div className="modelInfo">
							<h1>
								<img src={IconModel} alt="IconModel" /> {selectedOrder.model_name}
							</h1>
							<span>Номер контракта: {selectedOrder.contract_code}</span>
						</div>
						{/* For buying a car */}
						{/* <div className="salesDepartment">
							<img src={IconSmartphone} alt="smartphone" />
							<div>
								<h3 className="title">Отдел продаж</h3>
								<p className="phonenumber">+998 99 400 00 00</p>
							</div>
						</div> */}
						{/* For buying a car */}
					</div>
					{changeTab === "tab1" ? (
						<>
							<div className="carBox">
								<div className="carBg"></div>
								<img className="CarImage" src={selectedOrder.photo_sha} alt="CarImage" />
							</div>
						</>
					) : (
						<div className="barBox">
							<img className="CarImage" src={CarImageInner} alt="CarImage" />
						</div>
					)}
					<div className="creditAplication__tab">
						<div className={changeTab === "tab1" ? "activeView" : "backView"} onClick={handleChangeTabOne}>
							Вид спереди
						</div>
						<div className={changeTab === "tab2" ? "activeView" : "frontView"} onClick={handleChangeTabTwo}>
							Вид Сзади
						</div>
					</div>
					<div className="sendingTime">
						<h3 className="time">Время оформления • {selectedOrder.order_date}</h3>
						<div className="status">
							<span>Статус</span>
							{/* statusDone class is status done */}
							<p className="statusDone">{selectedOrder.state_html}</p>
						</div>
					</div>
				</div>
				<div className="creditAplication__info">
					<div className="leftContent">
						<h3 className="box-title">Комплектация</h3>
						<div className="leftContent__box">
							<h2>{selectedOrder.modification_name}</h2>
							<ul className="box-items">
								{selectedOrder.option_names.map((order) => {
									return (
										<li className="item">
											<img src={IconCheck} alt="item icon" />
											{order}
										</li>
									);
								})}
							</ul>
							<p className="price">{selectedOrder.price} UZS</p>
						</div>
						<h3 className="box-title">Дизайн</h3>

						<div className="leftContent__box">
							<h2>Цвет</h2>
							<ul className="box-items">
								<li className="item">
									<img src={IconColor} alt="item icon" />
									{selectedOrder.color_name}
								</li>
							</ul>
							<p className="price">0 UZS</p>
						</div>

						<h3 className="box-title">Опции</h3>
						<div className="box-options">
							{selectedOrder.addoptions.filter(e => e.value == "1").map((item) => (
								<h4 className="option">
									{item.name} <span>+{item.sprice} UZS</span>
								</h4>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="document">
					<div className="document__download">
						<div className="downloadContent">
							<img src={IconPDF} alt="iconPdf" />
							<div className="text">
								<h3>Договор №{selectedOrder.contract_code}</h3>
								<span>PDF</span>
							</div>
						</div>
						<a target="_blank" href={`https://evolutionmotors.uz:3000/download?id=${selectedOrder.contract_code}`} className="downloadBtn" type="button">
							<img src={IconDownload} alt="iconDownload" />
							Скачать
						</a>
					</div>
					{/* <button type="button" className="widthdraw">
						Отозвать заявку
					</button> */}
				</div>
			</div>
		</div>
	);
};

export default PopupCreditAplication;
