import React, { useState } from "react";
import { Select } from "components";
import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";
import "./service.scss";
import IconDownWhite from "assets/images/icons/icon-arrow-down-white.svg";
import arrowLeft from "assets/images/icons/icon-arrowbig-left.svg";
import arrowRight from "assets/images/icons/icon-arrowbig-right.svg";

const CarOptions = ["01 а 001 аа", "02 а 011 da", "01 b 010 db"];

const Service = () => {
	const [activeInfoTab, setActiveInfoTab] = useState("tabOne");

	const handleInfoTabOne = () => {
		setActiveInfoTab("tabOne");
	};
	const handleInfoTabTwo = () => {
		setActiveInfoTab("tabTwo");
	};

	return (
		<div className="profileService">
			<div className="profileService__header">
				<div className="left">
					<h3 className="title">Сервис</h3>
					<ul className="left__tab">
						<li onClick={handleInfoTabOne} className={`item ${activeInfoTab === "tabOne" ? "item-active" : ""}`}>
							Журнал т/о
						</li>
						<li onClick={handleInfoTabTwo} className={`item ${activeInfoTab === "tabTwo" ? "item-active" : ""}`}>
							Записаться на т/о
						</li>
					</ul>
				</div>
				<div className="right">
					<Select.DefaultSelect
						title="Chery Arrizo 5e"
						selectOptions={CarOptions}
						defaultItem="01 а 001 аа"
						blackTheme={true}
						IconArrow={IconDownWhite}
					/>
				</div>
			</div>
			<div className="profileService__content">
				<div className="content-pagination">
					<Pagination
						count={6}
						renderItem={(item) => (
							<PaginationItem
								components={{
									next: (props) => <img {...props} src={arrowRight} alt="iconleft" />,
									previous: (props) => <img {...props} src={arrowLeft} alt="iconleft" />
								}}
								{...item}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default Service;
