import React from 'react'
import { Button } from "components"
import IconAccepted from "assets/images/icons/icon-accepted.svg"
import "./acceptedAplication.scss"

const AcceptedAplication = ({ description, numberID, blackBtn = 'default' }) => {
  return (
    <div className="acceptedA">
        <p className="acceptedA__number">Номер заявки: ID{numberID}</p>
        <div className="acceptedA__content">
            <img src={IconAccepted} alt="acceptedIcon" />
            <h2 className="title">ваша заявка принята</h2>
            <p className="description">{description}</p>
            <Button.Link
                title="Мои заявки"
                url="/profile/aplication"
                hasIcon={false}
                hasIconArrow={false}
                rounded={true}
                borderType="full"
                size='large'
                type={blackBtn ? "black": "default"}
            />
        </div>
    </div>
  )
}

export default AcceptedAplication
