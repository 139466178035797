import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import axios from "axios";
import request from 'services/api'
import { Button, Popup } from 'components'
import { get } from "lodash";
import { useWindowDimensions } from "hooks";
import MobileHeader from "./MobileHeader";
import SearchIcon from 'assets/images/icons/icon-search.svg'
import IconCatalog from 'assets/images/icons/icon-catalog.svg'
import { useNavigate } from "react-router-dom";
import ArrowIcon from 'assets/images/icons/icon-arrow-down-fill.svg'
import HeaderLogo from 'assets/images/main-page/LogoEvolution.svg'
import { ReactComponent as IconBasket } from 'assets/images/icons/icon-basket1.svg'
import { ReactComponent as IconFavorite } from 'assets/images/icons/icon-favorite.svg'
import { ReactComponent as IconCompare } from 'assets/images/icons/icon-compare-states.svg'
import SelectLang from './components/SelectLang'
import { authenticationService } from "services/auth";
import Auth from 'pages/Auth';
import Search from "pages/Search";

const menus = [
  { title: "Главная", url: "/", status: 1 },
  { title: "Каталог", url: "catalog", status: 1 },
  {
    title: "Владельцам",
    url: "#",
    status: 1,
    menu_items: [{
      title: "Сервис",
      url: "/services-list/service",
      status: 1,
      menu_items: []
    },
      // {
      //   title: "Карта станций",
      //   url: "station",
      //   status: 1,
      //   menu_items: []
    // },
      {
        title: "Интерактивная карта",
        url: "/services-list/interactive-map",
        status: 1,
        menu_items: []
      }]
  },
  {
    title: "Покупателям",
    url: "#",
    status: 1,
    menu_items: [{
      title: "Конфигуратор",
      url: "configuration",
      status: 1,
      menu_items: []
    }, {
      title: "Кредит",
      url: "/services-list/credit-lising",
      status: 1,
      menu_items: []
    },
      {
        title: "Trade In",
        url: "/trade-in",
        status: 1,
        menu_items: []
      },{
        title: "Оформить страховку",
        url: "/getinsurance",
        status: 1,
        menu_items: []
      }]
  },
  { title: "Дилерам", url: "/dealers", status: 1, menu_items: [] },
  { title: "Новости и акции", url: "news", status: 1, menu_items: [] },
  { title: "О компании", url: "about", status: 1, menu_items: [] },
  { title: "Контакты", url: "contacts", status: 1, menu_items: [] }
];


const Header = () => {
  const { width } = useWindowDimensions();
  let location = useLocation();
  const currentRoute = location.pathname.split("/")[1];
  const [currentUser, setCurrentUser] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [showSearchModal, setShowSearchModal] = useState(false);

  const handleShowSearch = () => {
    setShowSearchModal(true);
  };

  useEffect(() => {
    authenticationService.currentUser.subscribe(x => {
      setCurrentUser(x)
      if (x && x.token) {
        axios.post('https://evolutionmotors.uz:3000/check', {
          token: x.token,
        }).then(e => {
          if (e.data.status != "OK") {
            authenticationService.logout()
          }
        })
      }
    });
  }, []);

  useEffect(() => {
    if (width <= 1220) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <>
      {
        isMobile ? (
          <MobileHeader menus={menus} currentRoute={currentRoute}/>
        ) : (
          <div className="headerWrapper">
            <Popup.BlackFullModal opened={showSearchModal} onClose={() => setShowSearchModal(false)}>
              <Search setShowSearchModal={setShowSearchModal}/>
            </Popup.BlackFullModal>
            <div className="subHeader">
              <h3 className="contact-support">Служба клиентской поддержки <span className="contact-number">+998 78 141 88 88</span>
              </h3>
              <div className="right-section">
                <img className="searchIcon" onClick={handleShowSearch} src={SearchIcon} alt="searchIcon"/>
                {/*<SelectLang/>*/}
                <div className="registration">
                 {currentUser ? (
                    <Button.Link
                      title={"Мой профиль"}
                      url="/profile/main"
                      size="signIn"
                      type='default'
                      svgColor='white'
                      iconBefore='user'
                      iconAfter="arrow-down"
                      hasIcon={true}
                      hasIconArrow={true}
                    />

                  ) : (
                    <Button.Link
                      onClick={() => setShowAuthModal(true)}
                      title={"Войти / Регистрация"}
                      // url="/profile/main"
                      size="signIn"
                      type='default'
                      svgColor='white'
                      iconBefore='user'
                      iconAfter="arrow-down"
                      hasIcon={true}
                      hasIconArrow={true}
                    />
                  )}
                  {
                    showAuthModal && <Auth setShowAuthModal={setShowAuthModal}/>
                  }
                </div>
              </div>
            </div>
            <nav className="nav">
              <div className="container">
                <nav className="nav__wrapper">
                  <div className="logo-box">
                    <Link
                      to='/'>
                      <img src={HeaderLogo} alt="HeaderLogo"/>
                    </Link>
                  </div>
                  <div className="left-content">
                    <ul>
                      {menus.map((menu, i) => {
                        return (
                          <li key={i}
                              className={`nav__item ${`${currentRoute}` === menu.url || (menu.url === "/" && currentRoute.length === 0) ? "active" : ""
                              } ${(menu.menu_items || []).length > 0 && "menu-dropdown"}`}>{(menu.menu_items || []).length == 0 ? (
                              <Link className="bordered" to={`${menu.url === "/" ? "" : menu.url}`}>{menu.title}</Link>
                            ) : (
                              <>
                                <Link
                                  to={menu.url}
                                  className={
                                    `bordered ${menu.menu_items.map(m => m.url === `${currentRoute}`)[0] ||
                                    menu.menu_items.map(m => m.url === `${currentRoute}`)[1]
                                      ? "activeDropdown"
                                      : ""}`
                                  }>
                                  {menu.url === "catalog" ? (
                                    <img className="IconCatalog" src={IconCatalog} alt="IconCatalog"/>
                                  ) : null}
                                  {menu.title}
                                  <img className="IconArrow" src={ArrowIcon} alt="arrow-down"/>
                                </Link>
                                <div className="transparent-box">
                                  <div className="rating-dropdown">
                                    {menu.menu_items.map((submenu, k) => {
                                      if (submenu.status === 1) {
                                        return (
                                          <div key={k}>
                                            <Link
                                              to={submenu.url}
                                              className={`dropdown__item ${(`${currentRoute}` === submenu.url ||
                                                `/${currentRoute}` === submenu.url) &&
                                              "activeSub"}`}
                                              key={k}>
                                              <span>{submenu.title}</span>
                                            </Link>
                                            <div className="divider"></div>
                                          </div>
                                        );
                                      } else {
                                        return "";
                                      }
                                    })}
                                  </div>
                                </div>
                              </>
                            )
                          }</li>
                        );

                      })}
                    </ul>
                  </div>
                  <div className="HeaderIcons">
                    <Link className={currentRoute.includes("compare") ? "activeHeaderCompareIcon" : "headerCompareIcon"}
                          to="/compare">
                      <IconCompare/>
                    </Link>
                    <Link className={currentRoute.includes("favorite") ? "activeFavoriteIcon" : "headerFavoriteIcon"}
                          to="/favorite">
                      <IconFavorite/>
                    </Link>
                  </div>
                </nav>
              </div>
            </nav>
          </div>
        )
      }
    </>
  )
}

export default Header
