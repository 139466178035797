import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import CreditLisingCard from "./components/CreditLisingCard";
import axios from "axios";
import { Popup } from "components";
import "./myaplication.scss";
import PopupCreditAplication from "./components/PopupCreditAplication";
import PopupTestDrive from "./components/PopupTestDrive";
import PopupAccessory from "./components/PopupAccessory";
import { authenticationService } from "services/auth";

const MyAplication = () => {
	const [activeTab, setActiveTab] = useState("tab1");
	const [openedModalCredit, setOpenedModalCredit] = useState(false);
	const [openedModalTestDrive, setOpenedModalTestDrive] = useState(false);
	const [openedModalAccessory, setOpenedModalAccessory] = useState(false);
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState();
	const [currentUser, setCurrentUser] = useState();

	useEffect(() => {
		authenticationService.currentUser.subscribe(x => {
			setCurrentUser(x)
			axios.post('https://evolutionmotors.uz:3000/orders', {
				token: x.token,
			}).then(e => {
				console.log(e)
				setOrders(e.data.data)
			})
		});
	}, []);


	const handleTab1 = () => {
		setActiveTab("tab1");
	};
	const handleTab2 = () => {
		setActiveTab("tab2");
	};

	return (
		<div className="myaplication">
			<h3 className="myaplication__title">Мои заявки</h3>
			<div className="tabWrapper">
				<ul className="myaplication__tab">
					<li onClick={handleTab1} className={`item ${activeTab === "tab1" ? "item-active" : ""}`}>
						Автомобили
					</li>

				</ul>
			</div>

			<div className="myaplication__cards">
				{orders.map((order) => {
					console.log(order)
					return (
						<div onClick={() => {
							setSelectedOrder(order)
							setOpenedModalCredit(true)
						}}>
							<CreditLisingCard order={order} />
						</div>
					);
				})}
			</div>
			<Popup.AnimatedFullModal title="Контракт" opened={openedModalCredit} onClose={() => setOpenedModalCredit(false)}>
				<PopupCreditAplication selectedOrder={selectedOrder} />
			</Popup.AnimatedFullModal>
		</div>
	);
};

export default MyAplication;
