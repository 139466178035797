import React, { useEffect } from "react";
import useStores from "./hooks/use-stores";

function App({ children }) {
	const { contentGuideStore: { getCompany } } = useStores();

	useEffect(() => {
		getCompany();
	}, []);

	return (
		<>
			<div id="custom-alert-zone" />
			{children}
		</>
	);
}

export default App;
