import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";
import "./payment.scss";
import arrowLeft from "assets/images/icons/icon-arrowbig-left.svg";
import arrowRight from "assets/images/icons/icon-arrowbig-right.svg";
import axios from "axios";
import { authenticationService } from "../../../../services/auth";

const Payments = () => {
  const [savdoId, setSavdoId] = useState();
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    if (savdoId) {
      axios.post('https://evolutionmotors.uz:3000/loadOrders', {
        "client_id": savdoId,
        "filial_id": "100"
      })
        .then(e => {
          setPaymentData(e.data.data);
        })
    }
  }, [savdoId])


  useEffect(() => {
    authenticationService.currentUser.subscribe(x => {
      axios.post('https://evolutionmotors.uz:3000/check', {
        token: x.token
      }).then(e => {
        if (e.data.status === "OK") {
          if (e.data.savdo_id !== -1) {
            setSavdoId(e.data.savdo_id)
          }
        }
      });
    });
  }, []);

  return (
    <div className="profilePayments">
      <h3 className="profilePayments__title">Оплаты</h3>
      <div className="profilePayments__table">
        <table>
          <thead>
          <tr>
            <th>Наименование</th>
            <th>Описание</th>
            <th>Количество</th>
            <th>Сумма</th>
            <th>Дата оплаты</th>
            <th>Статус</th>
            <th>Дата</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {paymentData.filter(i => i.paid_amount !== '0').map(item => (
            <tr>
              <td className="normalText">{item.contract_code}</td>
              <td className="normalText">{item.model_name}</td>
              <td className="normalText">{item.count_payment}</td>
              <td className="boldText">{item.paid_amount === '0' ? '' : item.paid_amount}</td>
              <td className="boldText">{item.expect_date}</td>
              <td className="boldText">{item.state}</td>
              <td className="boldText">{item.order_date}</td>
              <td className="boldText">
                <button className="pending" type="button">
                  Оплачено
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      {/*<div className="content-pagination">*/}
      {/*  <Pagination*/}
      {/*    count={10}*/}
      {/*    renderItem={(item) => (*/}
      {/*      <PaginationItem*/}
      {/*        components={{*/}
      {/*          next: (props) => <img {...props} src={arrowRight} alt="iconleft"/>,*/}
      {/*          previous: (props) => <img {...props} src={arrowLeft} alt="iconleft"/>*/}
      {/*        }}*/}
      {/*        {...item}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default Payments;
