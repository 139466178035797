import { makeAutoObservable } from 'mobx';
import { storage } from "../services";

export default class CompareStore {
  constructor(rootStore) {
    this.compareData = storage.get('compares') ? JSON.parse(storage.get('compares')) : [];
    this.maxCountCompare = 3;

    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  addCompare = (newItem) => {
    const newCompareData = [...this.compareData];
    newCompareData.push(newItem);

    storage.set('compares', JSON.stringify(newCompareData));
    this.setCompareData(newCompareData);
  };

  deleteCompare = (id) => {
    if (!id) {
      this.setCompareData([]);
      storage.set('compares', JSON.stringify([]));
    } else {
      const newCompareData = [...this.compareData].filter(item => item.id !== id);

      storage.set('compares', JSON.stringify(newCompareData));
      this.setCompareData(newCompareData);
    }
  }

  setCompareData = (data) => {
    this.compareData = data;
  }
}
