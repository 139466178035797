import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "assets/images/icons/icon-close-outline.svg";
import { ANIMATION_TIME } from "./const";
import styles from "./styles.module.css";
import animationStyles from "./animation.module.css";
import { ClickAwayListener } from "@mui/material";

const overlayAnimation = {
	enter: animationStyles.overlayEnter,
	enterActive: animationStyles.overlayEnterActive,
	exit: animationStyles.overlayExit,
	exitActive: animationStyles.overlayExitActive
};

const contentAnimation = {
	enter: animationStyles.contentEnter,
	enterActive: animationStyles.contentEnterActive,
	exit: animationStyles.contentExit,
	exitActive: animationStyles.contentExitActive
};

export const Layout = ({ onClose, children, title, opened }) => {
	const overlayRef = useRef();
	const contentRef = useRef();

	const [animationIn, setAnimationIn] = useState(false);

	useEffect(() => {
		setAnimationIn(opened);
	}, [opened]);

	return (
		<div className={styles.containerPopUp}>
			<CSSTransition in={animationIn} nodeRef={contentRef} timeout={ANIMATION_TIME} mountOnEnter unmountOnExit classNames={contentAnimation}>
				<div ref={contentRef} className={styles.content}>
					<CSSTransition in={animationIn} nodeRef={overlayRef} timeout={ANIMATION_TIME} mountOnEnter unmountOnExit classNames={overlayAnimation}>
						<div className={styles.header}>
							<h3 className={styles.title}>{title}</h3>
							<div className={styles.close}>
								<img ref={overlayRef} onClick={onClose} className={styles.closeIcon} src={CloseIcon} alt="closeIcon" />
							</div>
						</div>
					</CSSTransition>
					<div className={styles.overlayBg}></div>
					<ClickAwayListener onClickAway={onClose}>
						<div className={styles.children}>{children}</div>
					</ClickAwayListener>
				</div>
			</CSSTransition>
		</div>
	);
};
