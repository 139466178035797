
import React, { useState, useRef, useLayoutEffect } from "react";
import LastViewCard from "../components/LastViewCard";
import "./main.scss";
import CardImage2 from "assets/images/profile/car2.png";
import Ads from "../components/Ads";
import { storage } from "services";
const Main = () => {
	const [models, setModels] = useState([]);
	useLayoutEffect(() => {

		let last = storage.get('lastcars')
		if (last) {
			last = JSON.parse(last)
		} else {
			last = []
		}
		setModels(last)

	}, []);
	return (
		<div className="profileMain">
			<div className="profileMain__header">
				<h3 className="title">Вы недавно смотрели</h3>
			</div>
			<div className="profileMain__cards">
				{
					models?.slice(0, 5).map((item) => (
						<LastViewCard CardImage={item.photo} title={item.title} price={item.min} />
					))
				}
			</div>
			<div className="profileMain__ads">
				<Ads />
			</div>
			<div className="serviceAndHelp">
				<h3 className="title">Сервис и помощь</h3>
				<div className="wrapper">
					<div className="termsOfPayments">Условия оплаты</div>
					<div className="termsOfConditions">Условия возврата</div>
				</div>
			</div>
		</div>
	);
};

export default Main;
