import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "components";
import PropTypes from "prop-types";
import "./style.scss";

const LinkButtonComponent = ({
	hasIcon,
	prepend,
	onClick,
	iconBefore,
	iconAfter,
	width,
	append,
	hasIconArrow,
	svgColor,
	title = "",
	borderType,
	type = "link",
	url = "",
	size = "normal",
	className = "",
	rounded = false,
	children,
	tooltip,
	buttonType,
	...otherProps
}) => {
	const classNames = cx("btn", type && `btn-${type}`, size === "md" ? "" : `btn-${size}`, rounded && ` btn-rounded-${borderType}`, `btn-${width}`, className && className);

	return (
		<Link to={url} onClick={onClick} className={`link-button ${classNames}`} rel="noopener noreferrer" buttontype={buttonType} {...otherProps} title={tooltip}>
			{hasIcon && <Icon name={iconBefore} fill={svgColor} />}
			{prepend && prepend}
			{title ? <>{title}</> : <>{children}</>} {hasIconArrow && <Icon name={iconAfter} fill={svgColor} />}
			{append && append}
		</Link>
	);
};

LinkButtonComponent.propTypes= {
	onClick: PropTypes.func,
	type: PropTypes.oneOf(["default", "defaultBlack", "active", "black", "light", "disabled"]),
	iconBefore: PropTypes.oneOf(["catalog", "icon-rule", "basket","user"]),
	iconAfter: PropTypes.oneOf(["arrowbig-right", "arrow-down", "reset", "icon-rule"]),
	width: PropTypes.string

}

export default LinkButtonComponent;
