import React, { useState } from "react";
import axios from "axios";
import OtpInput from "react-otp-input";
import { Timer, Button } from "components";
import { authenticationService } from "services/auth";
import IconTimer from "assets/images/icons/icon-time.svg";
import "./phoneVerification.scss";

const PhoneNumberVerification = ({ data, setData, setCurrentStep, handleCloseModal}) => {
	const [otp, setOtp] = useState("");
	const [warning, setWarning] = useState(false);

	const handleChangeOtp = (value) => setOtp(value);
	const [sendDisabled, setSendDisabled] = useState(true);

	const handleNext = () => {
		axios.post(`https://evolutionmotors.uz:3000/validate`, {
			phone: data.phone,
			code: otp
		}).then(e => {
			if (e.data.status == "OK") {
				setCurrentStep((prevState) => prevState + 1)
				authenticationService.validate({
					id: e.data.id,
					token: e.data.token,
					phone: e.data.phone,
				})
				handleCloseModal()
			} else {
				setWarning(true)
				setOtp("")
			}
		})
	};

	return (
		<div className="verification">
			<div className="verification__input">
				<div className="verification__header">
					<h3 className="verification__header-title">Подтвердить номер</h3>
					<p className="verification__header-subtitle">Вам на номер +{data.phone} отправлен 4-ти значный пароль</p>
				</div>
				{!warning ? (
					<h3 className="verification__title">Введите 4х значный пароль</h3>
				) : (
					<h3 className="verification__title">Введенный вами код неверный</h3>
				)}

				<div className="verification__otpInput">
					<OtpInput
						value={otp}
						onChange={handleChangeOtp}
						numInputs={4}
						isInputNum
						containerStyle={{
							justifyContent: "center"
						}}
						focusStyle={{
							outline: 0,
							border: "1px solid #457FC1"
						}}
						inputStyle={{
							width: 48,
							height: 48,
							fontSize: "24px",
							color: "#0047FF",
							fontWeight: 500,
							borderRadius: 5,
							backgroundColor: "#F8F8F8",
							border: "1px solid #EFEFEF",
							margin: "0 4px",
							transition: "border .2s ease"
						}}
					/>
				</div>
				<div className="verification__timer">
					<img src={IconTimer} alt="icon timer" />
					<Timer initialMinutes={2} initialSeconds={0} setDisabled={setSendDisabled} />
				</div>
				<div className="verification__btn">
					<Button.Default
						title="Далее"
						onClick={handleNext}
						buttonType="button"
						type={otp.length === 4 ? "default" : "disabled"}
						size="large"
						rounded={true}
						hasIcon={false}
						hasIconArrow={false}
						borderType="half"
					/>
				</div>
			</div>
		</div>
	);
};

export default PhoneNumberVerification;
