import React, { useEffect } from "react";
import { UseTimer } from "hooks";
import "./timer.scss";
const Timer = ({ initialMinutes, initialSeconds, setDisabled }) => {
	const { minutes, seconds } = UseTimer(initialMinutes, initialSeconds);

	useEffect(() => {
		if (minutes === 0 && seconds === 0) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [minutes, seconds]);

	return (
		<div className="timer">
			<h3 className="timer__title">
				{`0${minutes}`}:{seconds < 10 ? `0${seconds}` : seconds}s
			</h3>
		</div>
	);
};
export default Timer;
