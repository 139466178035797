import React from "react";
import { Link } from "react-router-dom";
import "./myCar.scss";
import IconEllipse from "assets/images/icons/icon-ellipse.svg";
import CarImage from "assets/images/cars/carTabImage.png";
import CarNumber from "assets/images/profile/numberCar.png";
import Ads from "../components/Ads";

const MyCar = () => {
	return (
		<div className="myCar">
			<h3 className="myCar__title">Мое авто</h3>
			<div className="myCar__cards">
				<Link to="/profile/carinfo">
					<div className="myCar__card">
						<div className="carWrapper">
							<h4>
								<img src={IconEllipse} alt="carImage" /> Есть неоплаченные штрафы
							</h4>
							<img className="carImage" src={CarImage} alt="carImage" />
						</div>
						<h3 className="card-title">Chery Arrizo 5e</h3>
						<img className="carNumber" src={CarNumber} alt="numberImage" />
					</div>
				</Link>
				<div className="myCar__add-car">
					<h2>Добавить автомобиль</h2>
					<button className="addBtn" type="button">
						Добавить
					</button>
				</div>
			</div>
			<Ads />
		</div>
	);
};

export default MyCar;
